@charset 'UTF-8';
/*!
---------------------------------------
Project: Frontend Starterkit
Author: Volker Otto <hello@volkerotto.net> (http://volkerotto.net)
---------------------------------------
*/



@import 'vendor/normalize';

@import 'config/functions';
@import 'config/vars';

@import 'lib/fontawesome';
@import 'lib/brands';
@import 'lib/solid';
@import 'lib/regular';

@import 'mixins/global';
@import 'mixins/media';
@import 'mixins/grid';
@import 'mixins/typo';

@import 'basic/html';
@import 'basic/grid';
@import 'basic/helper';

@import 'elements/button';
@import 'elements/form';
@import 'elements/typo';
@import 'elements/card';
@import 'elements/product-card';

@import 'elements/branchenkachel';
@import 'elements/socialMedia';
@import 'elements/slick';
@import 'elements/pagination';

@import 'layout/header';
@import 'layout/box_header';
@import 'layout/footer';
@import 'layout/nav';
@import 'layout/main';

@import 'sections/hero';
@import 'sections/section';
@import 'sections/linkCards';
@import 'sections/landingCards';
@import 'sections/stellenausschreibung';
@import 'sections/textboxen';
@import 'sections/branche';
@import 'sections/slider';
@import 'sections/newsletter_infografic';
@import 'sections/texthighlight';

@import 'elements/info-box';

@import 'stellenangebote/jobs';
@import 'stellenangebote/menu';
@import 'stellenangebote/typography';
