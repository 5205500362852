/*
---------------------------------------
Grid Mixins
---------------------------------------
*/
@mixin container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $grid-max-width;
    padding-left: $spacing;
    padding-right: $spacing;
}

@mixin flex-container {
    @include container;
    display: flex;
    flex-wrap: wrap;
}

@mixin row {
    display: block;
    font-size: 0;
    margin: 0 $spacing / -2;
    padding: 0;
}

@mixin flex-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 $spacing / -2;
    padding: 0;
    width: auto;
    flex: 1 1 100%;
}

@mixin flex-options {
    &--row { flex-direction: row; }
    &--row-reverse { flex-direction: row-reverse; }
    &--column { flex-direction: column; }
    &--column-reverse { flex-direction: column-reverse; }
    &--nowrap { flex-wrap: nowrap; }
    &--wrap { flex-wrap: wrap; }
}

@mixin col {
    display: inline-block;
    font-size: 1rem;
    vertical-align: top;
    padding: 0 $spacing / 2;
    width: 100%;
    // flex: 0 0 100%;
    max-width: 100%;
}

@mixin flex-col {
    display: inline-block;
    padding: 0 $spacing / 2;
    width: 100%;
     flex: 0 0 100%;
    max-width: 100%;
    transition: all t(short);
}

@mixin generate-col($value) {
    $string_value: to-string($value);
    @if (ends-with($string_value, "3")){
        $value: $value + 0.33334;
    } @else if (ends-with($string_value, "6")){
        $value: $value + 0.66667;
    }
    width: 0% + $value;
    flex-basis: 0% + $value;
    max-width: 0% + $value;
}
