/*
---------------------------------------
Main
---------------------------------------
*/

.main {
    position: relative;
    display: block;
}

.main-wrapper {
    position: relative;

}

.skip-link {
    @include btn;
}

.back-to-top {
    position: fixed;
    bottom: $spacing;
    right: $spacing;
    padding: $spacing / 2;
    width: $spacing * 2.5;
    height: $spacing * 2.5;
    text-align: center;
    font-size: 1rem;
    line-height: 1.3;
    color: c(white);
    background: $primary;
    z-index: z(back-to-top);
    border-radius: 50%;

    &::before {
        @include icon('\f102');
    }

    &:focus,
    &:hover {
        background: darken($primary, 5%);
        color: c(white);
        cursor: pointer;
    }
}






#page > .panel.blog > div > * {
    margin-bottom: 16px;
    margin: 0;
    margin-bottom: 0px;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    color: #000;
}

.list > ul > li:nth-child(2n+1) {
    background-color: #f0ece7;
}
.list ul > li.small {
    height: 29px;
    line-height: 29px;
    padding-left: 10px !important;
    width: 500px;
    background-color: gainsboro;
}

.icon.small {
    padding-left: 29px !important;
    min-height: 29px !important;
}

.icon.after {
    
    position: relative;
    padding-right: 37px;
    min-height: 37px;
}
.icon::before {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    margin: 6px;
    //background-image: url(../img/symbols-25.png);
    background-repeat: no-repeat;
}
.list ul > li.small {
    line-height: 29px;
}
.list ul > li {
    white-space: nowrap;
    font: inherit;
}

ol, ul {
    list-style: none;
}
.list > ul > li:nth-child(2n+1) {
    background-color: #f0ece7;
}

