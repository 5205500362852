
.filterCaption {
	border-left: 10px solid c(white);
	border-right: 10px solid c(white);
}

@media (min-width: 640px) {/*others*/
	.jobFilter {
		display:block!important;
	}
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.jobFilter {
		display:none;
	}
}

// .filterbox .dimensions > .active {
// 	cursor:default;
// 	border-left: 1px solid c(silver);
// 	border-right: 1px solid c(silver);
// 	border-top: 1px solid c(silver);
// 	padding-right:5px;
// 	padding-left:5px;
// 	padding-bottom: 1px;
// 	color:$primary;
// }
//
// .filterbox .dimensions > li:first-of-type {
// 	border-left: 0px;
// 	padding-left:0px;
// }
//
// .filterbox .dimensions > li:last-of-type {
// 	border-right: 0px;
// 	padding-right:0px;
// }
//
// @media (min-width: 640px) and (max-width: 1000px) { /*medium*/
// 	.filterbox .dimensions > .active {
// 		padding-right:3px;
// 		padding-left:3px;
// 	}
//
// 	.filterbox .dimensions > li:first-of-type {
// 		padding-left:0px;
// 	}
//
// 	.filterbox .dimensions > li:last-of-type {
// 		padding-right:0px;
// 	}
//
// }
//
// .filterbox .dimensions > li:not(.active) {
// 	border-bottom: 1px solid c(silver);
// }

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.filterCaption {
		border: 0px!important;
	}

	.filterbox .dimensions > .active {
		padding:0px;
	}
}

.filterbox .aspects {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display:flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: auto -2px;
}

.filterbox .box {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display:flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items:flex-start;
	border:0px;
	margin:2px;
}

.filterbox .box,
.filterbox .box > *  {
	flex-grow:1;
}

.filterbox .box.tenth {
	width:200px;
}

.filterbox .box > h3 {
	margin-bottom:2px;
	flex: 0 1 100%;
}

/** breadcrump **/
	.filterbox .currentLevel {
		padding-left: 0;
	}
	.filterbox .currentLevel li {
		display: inline-block;
		line-height: 25px;
		position: relative;
		padding-left: $spacing;
		cursor: pointer;
	}

	.filterbox .currentLevel li:before {
		content:"";
		display:block;
		position:absolute;

		// background-image: url(../img/symbols-25.png);
		background-repeat: no-repeat;
		background-position: -5px -40px; /*black right*/

		height: 25px;
		width: 25px;
		margin-left:-25px;
	}

/** tag list **/
	.filterbox .activeFilter {
		width:100%;

		margin-top: 10px;
		line-height: 21px;

		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	.filterbox .activeFilter > * {
		display:inline-block;
		border: 1px solid c(silver);
		border-radius:15px;
		padding:3px;
		padding-left:10px;
		padding-right:10px;
		cursor:pointer;
		margin-right:10px;
		margin-bottom:10px;
	}

	.filterbox .activeFilter > *:after {
		content:"x";
		display:inline-block;
		margin-left:3px;
		color:c(black);
		line-height:21px;
	}

	.filterbox .activeFilter > *:hover:after {
		color:red;
	}

/* elements */

	.filterbox .list {
		margin-right:20px;
		overflow: hidden;
		width: 50px;
		padding-left: 0;
	}

	.filterbox .list:last-child {
		margin-right:0px;
	}

	.filterbox .list li {
		text-decoration: none;
		color: c(white);
		text-overflow: ellipsis;
		display: block;
		line-height: 25px;
		padding: 6px 10px;
		cursor: pointer;

		overflow:hidden;
		white-space: nowrap;
		padding-left:25px;
	}

	.filterbox .list li:before {
		content:"";
		display:block;
		position:absolute;

		// background-image: url(../img/symbols-25.png);
		background-repeat: no-repeat;
		background-position: -5px -40px; /*arrow black right*/

		height: 25px;
		width: 25px;
		margin-left:-25px;
	}

	.filterbox .list li:before {
		background-position: -5px -40px; /*arrow black right*/
	}

	.filterbox .list li:hover:before {
		background-position: -5px -75px;  /*arrow right white */
	}

	.filterbox .list li.active:before {
		background-position: -215px -180px; /*check black*/
	}

	.filterbox .list li.active:hover:before {
		background-position: -215px -180px;  /*check black*/
	}

	.filterbox .list li:nth-child(2n+1) {
		background-color: c(middlesilver);
	}

	.filterbox .list li:nth-child(2n) {
		background-color: c(middleblue);
	}

	.filterbox .list li:last-child {
		border-bottom:0px;
	}

	.filterbox .list li:hover {
		background-color: c(lightblue);
	}

	.filterbox .button {
		text-decoration: none;
		color: c(white);

		display: block;
		line-height: 25px;
		padding: 6px 1rem;
		cursor: pointer;

		overflow:hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-left:25px;
		width: auto;
	}

	.filterbox .button:before {
		content:"";
		display:block;
		position:absolute;

		//background-image: url(../img/symbols-25.png);
		background-repeat: no-repeat;
		background-position: -5px -40px; /*black right*/

		height: 25px;
		width: 25px;
		margin-left:-25px;
	}

	.filterbox .button:hover:before {
		background-position: -5px -75px;
	}

	.filterbox .button.active:before {
		background-position: -215px -180px; /*check black*/
	}

	.filterbox .button.active:hover:before {
		background-position: -215px -180px;  /*check black*/
	}

	.filterbox .button:nth-child(2n+1) {
		background-color: c(middlesilver);
	}

	.filterbox .button:nth-child(2n) {
		background-color: c(middleblue);
	}

	.filterbox .button:hover {
		background-color: c(silver);
	}


@media (min-width: 0px)  and (max-width: 680px) { /** Minimum **/
	// .filterbox .dimensions > * {
	// 	display:block;
	// }

	.filterbox .box {
		display:block;
	}

	.filterbox .box > * {
		display:block;
		width:100%;
	}

	.filterbox .activeFilter > * {
		display:block;
		margin-top:5px;
		margin-bottom:5px;
		margin-right:0px;
	}

	.filterbox .button {
		width:auto;
		white-space: normal;
	}
}



/* some captions and other random manipulations*/

.filterbox .removeFilter:before {
	content:"Filter entfernen:";
	display:inline-block;
}

.jobsHeadline {
	font-size: 15px;
	text-decoration: none;
	padding: 0px;
	border-top: 1px solid c(silver);
	line-height: 35px;
	min-height: 35px;
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.jobsHeadline {
		font-size: 20px;
	}
}

/**
	Job Overview
**/
.jobTable {
	width:100%;
	border-top:1px solid c(silver);
	border-bottom:1px solid c(silver);
	-webkit-border-radius: 14px;
	-moz-border-radius: 14px;
	border-radius: 14px;
	border-collapse:separate !important;
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.jobTable {
		background-color: c(lightsilver);
	}
}


.jobTable a, .jobTable a:hover {
	color:black;
	text-decoration:none;
}

.jobTable tr:hover td {
	background-color:c(lightblue);
	cursor:pointer;
}

.jobTable tr:hover .purple {
	color: black !important;
}

.jobTable th {
	text-align:left;
	border-bottom: 1px solid c(silver);
	padding-left:5px;
	height:30px;
	vertical-align:middle;
	background-color:c(lightsilver);

}

.jobTable th:nth-child(2), .jobTable th:nth-child(3), .jobTable th:nth-child(4) {
	border-left: 1px solid c(silver);
	padding-right: 5px;
}

.jobTable td {
	height:60px;
	border-bottom:1px solid c(silver);
	vertical-align:middle;
	padding:5px;
}

.jobTable tr td:nth-of-type(1) {
	padding:0px;
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.jobTable tr td:nth-of-type(1) {
		padding-left:10px;
	}
}

.jobTable tr:last-child td {
	border-bottom:0px;
}

.jobTable td:nth-child(2), .jobTable td:nth-child(3), .jobTable td:nth-child(4) {
	border-left: 1px solid c(silver);
}

.jobTable .tbicon_large img {
	width:50px;
	height:50px;
	vertical-align:middle;
}

.jobTable .tbicon_small img {
	width:25px;
	height:25px;
	vertical-align:middle;
}

.jobPagination {
	margin-top:10px;
	text-align:right;
	margin-bottom:20px;
}
.jobsPagination a {
    width: 1.5rem;
    display: inline-block;
}
/*knt:
table layout crash below 800px width.
following styles break table into 2 rows apart
*/

/*minimal*/
@media (min-width: 0px)  and (max-width: 800px) {

	.jobTable {
		border-radius: 0px;
		border-top:0px;
		position:relative;
	}

	.jobTable th {
		display:none;
	}

	.jobTable tr {
		position: relative;
		z-index: 1;
	}

	.jobTable td {
		display:block;
		float:left;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		overflow:hidden;
	}

	.jobTable tr:hover td {
		cursor:pointer;
	}

	.jobTable .tbicon_small img {
		width:25px;
		height:25px;
		vertical-align:middle;
		padding-top:15px;
	}

	.jobTable td:nth-child(1) {
		border:0px;
		border-top:1px solid black;
		width:100%;
		height:auto;
		font-size:16px;
	}

	.jobTable td:nth-child(1) .purple {
	}

	.jobTable td:nth-child(2) {
		border:0px;
		width:100%;
		text-align: right;
		float:left;
		height:30px;
		padding-left:0px;
	}

	.jobTable td:nth-child(3) {
		border:0px;
		float:left;
		height:30px;
		white-space: nowrap;
		width:100%;
		text-align: right;
	}

	.jobTable td:nth-child(4) {
		border:0px;
		margin-top: -60px;
		position: relative;
		display:block;
		z-index: 2;
		padding-left: 10px;
	}

	.jobTable td:nth-child(5) {
		border:0px;
		margin-top: -60px;
		position: relative;
		display:block;
		padding-left: 45px;
	}
}

/**
	Job Pagination
**/

.jobsPagination {
	margin-top:10px;
	text-align:right;
	font-size: 17px;
}

.jobsPagination a {


	font-size: 15px;
	color: c(black);
	text-decoration:none;
}

.jobsPagination a.current {
	color: $primary;
	cursor:text;
}

.jobsPagination a:not(.current):hover {
	color: $primary;
	font-weight: bold;
}

/**
* 	JobDetails
**/
.box.jobDetail .body {
	background-color: c(lightsilver);
	border:none;
}

.box.jobDetail .text h1 {
	font-size: 37px;
}

@media (min-width: 0px)  and (max-width: 680px) { /** Minimum **/
	.box.jobDetail .text h1 {
		font-size: 22px;
		text-align: center;
	}

	.box.jobDetail .text h2 {
		font-size: 18px;
		text-align: center;
	}
}

.box.jobDetail .text h1 + h2 {
	font-weight:normal;
	margin-top:-10px !important;
	margin-bottom:30px;
	font-size:20px;
	color:$primary;

}

.box.jobDetail .text h3 {
	margin-top:20px;
	margin-bottom:0px;
	display:block;
	clear:both;
	color:$primary;
	font-weight:normal;
}

.box.jobDetail .text h3 + p {
	margin-top:3px;
}

.box.jobDetail .text h3 + ul {
	margin-top:0px;
	margin-bottom:0px;
}

@media print {
	.debug,
	.breadcrumb,
	.topmenu,
	.bottommenu,
	.logo,
	.menu {
		display:none;
	}

	.page > .content .one_third,
	.page > .content .two_third {
		display:none;
	}

	.page > .content > .right .box {
		display:none;
	}

	.page > .content > .right .box:first-child {
		display:block;
	}

}

/**
Job Forms
**/

	#frmCallback, #frmApply, #frmRequest, #frmContact {
		display:none;
	}

@media (min-width: 640px) and (max-width: 1000px) {/*medium*/
	#frmRequest .lineWrapper .quad {
		width:50%;
	}
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	#frmRequest .quad {
		width:100%;
		word-break: break-all;
	}
}

	.dialogForm {
		overflow:hidden;
		padding: 10px;
		margin-bottom: 15px;
		background-color: c(lightsilver);
	}

	.dialogForm h4 {
		font-size: 20px;
		color: c(black);
		margin-top: 10px;
		margin-bottom: 0px;
		padding: 5px;
	}

	.dialogForm label {
		display:block;
		margin-top:5px;
	}

	.dialogForm select, .dialogForm input, .dialogForm textarea {
		width:100%;
		height:30px;
		margin:0px;
		padding:0px;
		/*background-color:#DEE6C8;*/
	}

	.dialogForm textarea {
		height:300px;
	}

	.dialogForm div.field {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width:50%;
		float:left;
	}

	.dialogForm div.full {
		width:100%;
	}

	.dialogForm div.halv {
		width:50%;
	}

	.dialogForm div.right {
		float:right;
	}

	.dialogForm div.quad {
		width:25%;
	}

	.dialogForm div.quad3 {
		width:75%;
	}

	.dialogForm div.trie {
		width:33%;
	}

/** captcha**/
	.ident_answer img {
		margin:10px;
		margin-bottom:0px;
		padding:5px;
		cursor:pointer;
		border:1px solid c(white);
	}

	.ident_answer img:hover {
		background-color:c(green);
	}

	.ident_answer  img.selected {
		border:1px solid c(green);
	}

/**
* offerServices
**/
	.offerServices:not(.orderSummary) {
		padding:10px;
		margin-bottom:15px;
		background-color:c(lightsilver);
	}

	.offerServices.orderReceived {
		text-align:center;
		background-color: $primary;
		color: white;
	}

	.offerServices.orderReceived strong {
		font-size: 19px;
	}

	.offerServices .serviceTitle ,.offerServices .serviceTitle strong{
		font-size:19px;
	}

	.offerServices:not(.buy) .serviceTitle {
		margin-top: 0px;
	}

	.offerServices .service  > div, #sum  > div, #sumB  > div, #vat  > div {
		float:left;
		text-align:center;
	}

	.offerServices .service  div.button {
		text-align:center;
		float: none;
		width: auto;
		margin-left: 0px;
	}

	.offerServices  div.service {
		padding: 15px 0px;
	}

	.offerServices  div.service:not(:last-of-type) {
		border-bottom: 1px solid c(silver);
	}

	.offerServices.buy:first-of-type  div.service:not(:last-of-type) {
		border-bottom: none;
	}

	.offerServices .service div.button {
		box-sizing: border-box;
	}

	.offerServices .service > div.serviceDesc{
		width:80%;
		text-align:left;
	}

	.offerServices .service > div.serviceRight{
		width:20%;
		text-align:right;
	}

	.offerServices.buy.done .serviceDesc {
		width:100%;
	}

@media (min-width: 0px) and (max-width: 639px) {/*medium*/
	.offerServices .service > div.serviceDesc,.offerServices .service > div.serviceRight{
		width:100%;
	}

	.offerServices .service > div.serviceRight{
		margin-top:15px;
	}
}

	.offerServices:not(.orderSummary) .service > div.serviceRight{
		line-height: 6px;
	}

	.offerServices.buy.plus .service > div.serviceDesc {
		width:70%;
		text-align:left;
	}

	#sum  .sumCaption, #sumB .sumCaption, #vat  .sumCaption {
		width:74%;
		text-align:left;
	}

	.offerServices .service div.serviceDesc p {
		margin-right:20px;
	}

@media (min-width: 0px) and (max-width: 639px) {/*medium*/
	.offerServices .service div.serviceDesc p {
		margin-right:0px;
	}
}

	.offerServices .service div.serviceDesc  a {
		margin-top:5px;
	}

	.offerServices:not(.orderSummary) .service div.price{
		min-height:1px;
		font-size:45px;
		padding-bottom: 15px;
	}
@media (min-width: 640px) and (max-width: 1000px) {/*medium*/
	.offerServices:not(.orderSummary) .service div.price{
		font-size:28px;
	}
}

@media (min-width: 0px) and (max-width: 639px) {/*medium*/
	.offerServices:not(.orderSummary) .service div.price{
		font-size:8vw;
	}
}

	#sum  .sumValue ,#sumB  .sumValue , #vat  .sumValue  {
		width:26%;
		min-height:1px;
	}

@media (min-width: 0px) and (max-width: 639px) {/*medium*/
	#sum  .sumCaption,#sumB  .sumCaption, #vat  .sumCaption {
		width:65%;
	}

	#sum  .sumValue ,#sumB  .sumValue , #vat  .sumValue  {
		width:35%;
	}
}

	.offerServices.buy .service div.price, #sum  .sumValue, #sumB .sumValue, #vat  .sumValue  {
		text-align: right;
	}

	.offerServices .service div.addService img{
		width:65%;
		float:left;
		// background-image:url(/workspace/image/icon/plus.png);
		background-repeat:no-repeat;
		background-size: contain;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.offerServices .service div.addService img:hover {
		// background-image:url(/workspace/image/icon/plus-pink.png);
		cursor:pointer;
	}

	.offerServices .inCart div.addService img{
		// background-image:url(/workspace/image/icon/checked.png);
	}

	.offerServices .inCart div.addService img:hover {
		// background-image:url(/workspace/image/icon/checked-pink.png);
	}

	.offerServices .service div.addService {
		width:10%;
	}

	.offerServices.furtherService  .service div.addService {
		width:5%;
	}

	.offerServices.furtherService.buy.plus .service > div.serviceDesc {
		width: 75%;
	}

	.offerServices h2{
		font-size: 37px;
		border-bottom:1px solid c(silver);
	}

	.offerServices h3{
		font-size: 19px;
		border-bottom:1px solid c(silver);
	}

@media (min-width: 640px) and (max-width: 1000px) {/*medium*/
	.offerServices h2{
		font-size: 4vw;
	}
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.offerServices h2{
		font-size: 7vw;
	}
}

	.offerServices small{
		font-size: 12px;
	}

	.offerServices .panel {
		display: none;
		width:100%;
	}

	.offerServices .text a.more {
		// background-image: url(/workspace/image/icon/arrow_down_green_small.png);
		padding-right:25px;
	}

	#cart,#sum,#sumB,#vat {
		font-size: 19px !important;
	}

	#cart  {
		padding-top:10px;
	}

	#cart  > div, #sum  > div, #sumB  > div, #vat  > div {
		border-left: 10px solid transparent !important;
		box-sizing: border-box;
	}

	#cart div.price p {
		margin-top: 0px;
	}

	#cart .service {
		padding:0px;
		border:none;
	}

	#cart .service:last-of-type {
		padding-bottom:10px;
	}

	#sum  {
		border-top: 1px solid c(silver);
		padding-top: 5px;
		font-weight: bold;
	}

	#vat,#sumB  {
		border-bottom: 1px solid c(silver);
		padding-bottom: 5px;
	}

	.offerServices .formBottom {
		padding:20px 5px 0px 5px;
	}

	.formHead  {
		padding-top:10px;
		margin: auto -5px;
	}

	div.devider {
		width: 100%;
		border-bottom: 1px solid c(silver);
		float: left;
		padding-top: 10px;
		margin-bottom: 10px;
	}

	.submit {
		border:none;
		text-align:center !important;
	}

	.accountingAddress {
		display:none;
	}

	.showAccounting {
		text-decoration: underline;
		color: black;
		background-repeat: no-repeat;
		background-position: 100% center;
		padding-right: 17px;
		cursor: pointer;
		width: 10%;
		// background-image: url(/workspace/image/icon/arrow_down_green_small.png);
		padding-right: 25px;
		display: inline;
	}

	.accounting .showAccounting.active {
		// background-image: url(/workspace/image/icon/arrow_up_green_small.png);
	}

@media (min-width: 0px) and (max-width: 639px) {/*medium*/
	.submit {
		width:100% !important;
	}
}

	.field.quad.right .submit {
		width:100% !important;
	}

	.lineWrapper{
		display: inline-block;
		height: auto;
		float: left;
		width: 100%;
	}

	.blueFocus {
		box-shadow: 0px 0px 4px 1px c(middleblue);
	}
