/*
---------------------------------------
Mixins for Media Queries
---------------------------------------
*/

@mixin min($value){
    @media (min-width: $value) {
        @content;
    }
}

@mixin max($value){
    @media (max-width: $value) {
        @content;
    }
}

@mixin only($min, $max){
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}

/**
 * Defined Breakpoints
 *
 * Uses the defined breakpoints and automatically strips 1px off on max-width calculations
 * @param  {string} $bp       defined in vars
 * @param  {enum} $type:min min/max
 * @return media query
 */
@mixin bp($bp, $type:min) {
    $value: map-get($grid-breakpoints, $bp);

    @if $value != null {
        @if $type == min {
            @include min($value) {
                @content;
            }
        } @else if $type == max {
            $value: $value - 1;
            @include max($value) {
                @content;
            }
        }
    } @else {
        @warn 'Value `#{$bp}` could not be retrieved from $grid-breakpoints map. Please make sure it is defined in `_vars.scss` or use mixins `min`, `max`, `only`.';
    }
}

// Retina devices
@mixin retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        @content;
    }
}
