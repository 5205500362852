/*
---------------------------------------
Hero
---------------------------------------
*/

.section.section--hero {
    padding: 12rem 0 4rem;
    background-position: center;
    background-position: 37.5% 26.19%;
}


.section.section--hero-tall {
        padding: 25rem 0 4rem;
        background-position: 37.5% 26.19%;
}

.hero {
    display: flex;
    color: c(white);
    background: $secondary;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    align-content: center;
    align-items: center;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: $spacing * 2.5;

    .breadcrumb {
        text-align: left;
        margin-top: $spacing * -3;
        margin-bottom: $spacing * 2;
        display: none;

        @include bp(md){
            margin-top: $spacing * -12;
            margin-bottom: $spacing * 12;
            display: block;
        }

        ul {
            list-style: none;
            display: inline-flex;
            padding: 0;
            backdrop-filter: invert(40%);

            li {
                margin-right: 0.2rem;
                a {
                    color: c(white);
                }
            }
        }
    }
}

.section.hero--portal{
    min-height: $spacing * 24;
    margin-bottom: 0;
}

.hero__inner {
    margin: 0 auto;
    padding: $spacing;
    background-color: rgba(255, 204, 0, .8);
    margin-left: auto;
    margin-right: $spacing * -1;
    margin-bottom: $spacing * -3;
    max-height:200px;
    overflow:hidden;

    @include bp (sm) {
    }
    @include bp(md) {
        width: calc(60% + 0.5rem);
        max-width: $grid-max-width;
        padding: $spacing *2 $spacing;
    }
}

.hero__title{
    font-size: 2.2rem;
    color: c(black);
    font-weight: 500;
    margin-bottom: $spacing;
}


@media only screen and (max-width: 1000px) {
  .hero__title {
     font-size: 2rem;


  }
}
.hero__text {
    font-size: 2rem;
    color: c(black);
    font-weight: 500;
    margin-bottom: $spacing;

    p {
        @include lead;
    }
}

.hero__btn {
    margin: $spacing auto;
}

.slide__wrapper {
    display: flex;
    align-items: center;

    vertical-align: middle;
    margin: auto;
    color: c(black);

    .slide__icon {
        width: auto;
        height: auto;

        svg, img {
            height: 61px;
            width: auto;
        }
    }
    .slide__headline {
        margin-left: 1rem;

        p {
            font-size: 2rem;
            line-height: 1;
            font-weight: 500;
            color: c(black);
        }

    }
}
