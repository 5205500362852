/**
 * Hidden Classes
 *
 * Usage: hidden-{breakpoint}-up/down
 */
@each $name, $size in $grid-breakpoints {
    @include bp($name) {
        .--hidden-#{$name}-up { display: none; }
    }
    @include bp($name, 'max') {
        .--hidden-#{$name}-down { display: none; }
    }
}

// Text Alignment
// Usage: --text-center
.--text {
    &-center { text-align: center; }
    &-right  { text-align: right; }
    &-left   { text-align: left; }
    &-upper  { text-transform: uppercase; }
    &-lower  { text-transform: lowercase; }
    &-bold   { font-weight: bold; }
    &-italic { font-style: italic; }
}

// Float
// Usage: --float-left
.--float {
    &-left { float: left; }
    &-right { float: right;}
}

.--aspect-ratio {
    position: relative;

    &-1-1  { padding-bottom: 100%; }
    &-4-3  { padding-bottom: 75%; }
    &-3-2  { padding-bottom: 66.66%; }
    &-8-5  { padding-bottom: 62.5%; }
    &-16-9 { padding-bottom: 56.25%; }

    > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

// Clearfix
.clearfix::after {
    content: '';
    display: table;
    clear: both;
}

// Screen readers only
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;

    &.sr-only--focusable {
        &:active,
        &:focus {
            position: static;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            clip-path: none;
        }
    }
}
.--landscape {
    display: none;

    @include bp(md) {
        display: block;
    }
}

.--potrait {
    display: block;

    @include bp(md) {
        display: none;
    }
}
