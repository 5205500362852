/*
---------------------------------------
Info-Box
---------------------------------------
*/

.info-box {
    position: relative;
    display: block;
    padding: $spacing * 2;
    margin: $spacing 0;
    background: rgba($primary, .2);
    border: 1px solid $primary;
    z-index: z(default);

    a {
        color: inherit;
        font-weight: bold;

        &:focus,
        &:hover {
            border-bottom: 1px solid;
        }
    }

    p {
        margin-bottom: 0;
    }


    &.info-box--hidden {
        display: none;
    }

    &.info-box--alert {
        background: rgba(c(red), .2);
        border-color: c(red);
    }

    &.info-box--success {
        background: rgba(c(green), .2);
        border-color: c(green);
        color: c(black);
    }
}

.info-box__headline {
    display: block;
    font-weight: bold;
    font-size: 1.2rem;
}

.info-box__close {
    position: absolute;
    top: $spacing;
    right: $spacing;
}
.success {
    padding: 0;
}
