/*
---------------------------------------
Navigation
---------------------------------------
*/
.toggle-nav {
    display: block;
    margin-right: 0;
    margin-left: 0;
    color: c(white);
    padding: 1rem $spacing * 1.5;
    font-size: 1.2rem;

    @include bp(lg) {
        display: none;
    }

    &::after {
        @include icon('\f0c9');
    }

    &.nav--open {
        &::after {
            @include icon('\f00d');
            opacity: .8;
        }
    }
}

.nav {
    display: none;
    margin-left: 0;
    margin-right: auto;
    width: 100%;
    // transition: border t(short);
    border-top: 1px solid c(transparent);
    order: 0;

    @include bp(lg) {
        display: block;
        width: auto;
    }

    &.nav--open {
        display: block;
        border-top: 1px solid c(grey);

        @include bp(lg) {
            border-top: 0;
        }
    }

    a {
        display: block;
        color: c(white);
        padding: 1em;
        font-size: .875rem;
        font-weight: 500;

        &:focus,
        &:hover {
            outline: none;
            color: c(white);
            background-color: c(silver);
            border-radius: $border-radius;
        }

        &:active,
        &.nav__item--active {
            color: $secondary;
        }
    }
}

.nav__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav__item {
    display: block;

    @include bp(lg) {
        display: inline-block;
    }

    > .nav__list {
        display: none;
    }

    &.nav__item--hasdrop {
        position: relative;

        > a:after {
            @include icon('\f107');
            padding-left: .5em;
            position: absolute;
            right: 1rem;
            font-family: "Font Awesome 6 Pro";

            @include bp(lg) {
                position: static;
            }
        }
    }

    &.nav__item--open {

        > a:after {
            @include icon('\f106');
        }
        .nav__dropdown,
        .nav__mega {
            display: block;
        }
    }
}

.nav__dropdown {
    display: none;
    background: c(white);

    @include bp(lg) {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 230px;
        z-index: z(dropdown);
    }

    .nav__item {
        display: block;
    }

    .nav__item--last & {
        @include bp(lg) {
            right: 0;
            left: auto;
        }
    }

    a {
        color: rgba(c(black) ,.7);
        border-bottom: 1px solid c(grey);

        &:hover,
        &:focus {
            color: c(black);
        }
    }
}

.nav__mega {
    display: none;
    background: c(white);

    @include bp(lg) {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 30rem;
        z-index: z(dropdown);
    }

    .nav__item--last & {
        @include bp(lg) {
            right: 0;
            left: auto;
        }
    }
}

.mega__img {
    display: block;
}

.mega__content {
    padding: $spacing 0;
}

.mega__headline {
    @include p;
    font-size: 1rem;
    font-weight: 500;
}

.mega__list {
    margin: 0;
    padding: 0;
    list-style: none;

    li a {
        padding: $spacing *.5;

        &::before {
            @include icon('\f0da');
            margin-right: $spacing *.5;
        }
    }
}
