/*
---------------------------------------
Forms and Input Elements
---------------------------------------
*/

.field {
    position: relative;
    display: block;
    margin: 0 0 $spacing *2;

    .btn {
        display: block;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 0;
    }
}

.field--btn {

    @include bp(lg) {
        margin: 0
    }
}

// validate.js
.field__message {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    font-size: .875rem;
    color: c(red);
    padding: $spacing * .5 0;
}

.label {
    display: block;
    margin-top: 0;
    margin-bottom: $spacing * .5;

    input[type="checkbox"],
    input[type="radio"] {
        margin-right: $spacing / 2;
    }

    &.label--inline {
        display: inline-block;
        margin-right: $spacing;
    }

    &.label--success {
        color: c(green);
    }

    a {
        color: $primary;
    }
}


.input {
    display: inline-block;
    margin: 0;
    padding: $spacing *.75;
    outline: none;
    vertical-align: top;
    color: c(black);
    background: c(white);
    border: 1px solid c(grey);
    transition: all .2s ease;
    font-size: 1rem;

    &:hover {
        border-color: c(grey);
    }

    &:active,
    &:focus {
        border-color: darken(c(grey), 10%);
    }

    &.input--full {
        width: 100%;
    }

    &.input--success {
        border-color: c(green);
    }

    &.input--error {
        border-color: c(red);
    }
}


.select {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-top: 0;
    margin-bottom: $spacing;
    margin-right: $spacing / 2;
    outline: none;
    vertical-align: top;
    color: c(black);
    background: c(white);
    border: 1px solid c(grey);
    transition: all .2s ease;

    &::after {
        @include icon('\f107');
        position: absolute;
        top: 50%;
        right: $spacing * .8;
        transform: translateY(-50%);
        line-height: 1;
        pointer-events: none;
        color: c(grey);
        font-family: "Font Awesome 6 Pro";
    }

    &:focus,
    &:hover,
    &:active {
        border-color: darken(c(grey), 10%);

        &::after {
            color: darken(c(grey), 10%);
        }
    }

    select {
        display: block;
        position: relative;
        box-shadow: none;
        appearance: none;
        padding: $spacing *.75;
        padding-right: $spacing * 2;
        width: 100%;
        border: 0;
        border-radius: 0;
        background: c(transparent);

        &:focus {
            outline: none;
        }

        &:-moz-focusring {
            color: c(transparent);
            text-shadow: 0 0 0 #000;
        }

        option[disabled] {
            color: c(grey);
        }
    }

    .select__label {
        display: inline-block;
        font-weight: bold;
        padding-left: $spacing;
        user-select: none;

        &+select {
            display: inline-block;
            width: auto;
        }
    }
}

.drop {
    background-color: c(white);
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;

    i {
        color: c(silver);
        width: 5rem;
        text-align: center;
        font-size: 4rem;
        margin-left: auto;
        margin-right: auto;
        vertical-align: middle;
        transition: translateY(-50%);
        margin-top: auto;
        margin-bottom: 0;
        opacity: .2;
        display: block;

    }

    .fa-check {
        color: c(green);
        opacity: 1;
    }
}

.uploads {
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

}

.uploadedData {
    margin: 0.25rem 1rem 0.25rem 0;
    flex: 0 0 auto;
    background-color: #fff;
    margin-right: 1rem;
    display: block;
    list-style: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.25rem 0.6rem 0.3rem 0.6rem;
    border-radius: $border-radius;
    border: solid 1px #eaeaea;
    position: relative;

    i {
        color: c(black);
    }

    span {
        color: c(white);
        cursor: pointer;
        opacity: 0;

        i {
            opacity: 0;
        }
    }

    &:hover,
    &:focus,
    &:active {
        background-color: rgba(0, 0, 0, .1);
        box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.125);

        // &::after {
        //     @include icon('\f00d');
        //     position: absolute;
        //     color: c(silver);
        //     top: 50%;
        //     bottom: 0;
        //     right: .5rem;
        //     transform: translateY(-50%);
        //     cursor: pointer;
        //
        // }

        span {
            color: c(silver);
            cursor: pointer;
            display: inline;
            opacity: 1;

            i {
                color: c(silver);
                display: inline;
                opacity: 1;
            }
        }

    }

}

.removed {
    display: none;
}
