@media (min-width: 1001px) {/*maximal*/}
@media (min-width: 640px) and (max-width: 1000px) {/*medium*/}
@media (min-width: 0px) and (max-width: 639px) {/*minimal*/}

/**
*	Top Menu
**/

table {
	border-spacing: 0;
}

.topmenu {
	height:45px;
	background-color:c(silver);
	margin-bottom: 15px;
	min-width: 320px;
}

.linkWrapper {
	max-width:1280px;
	padding-left: 25px;
	padding-right: 25px;
	box-sizing: border-box;
	margin: auto;
}

.linkWrapper > * {
	display:block;
	float:right;
	margin-top:13px;
	margin-bottom:13px;
	margin-right:14px;
	margin-left:14px;
	cursor:pointer;
	font-size:13px;
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.linkWrapper {
		padding-right:0px;
		padding-left:0px;
	}
}

.topmenu a {
	color:c(white);
	text-decoration: none;
	line-height:23px;
}

.topmenu a:nth-of-type(1), .topmenu a:nth-of-type(2), .topmenu a:nth-of-type(3) {
	float:left;
}


.topmenu a:hover {
	color:c(green);
}

.topmenu input {
	height:21px; /*image height*/
	border:1px solid c(white);
	background-color:c(black);
	color:c(white);
	padding-left:5px;
	cursor:text;
}

.topmenu input:focus, .topmenu input:hover {
	border:1px solid c(green);
}

.topmenu img {
	width:23px;
	height:23px;
	background-size: 23px;
}

// .topmenu img.facebook {background-image: url("/workspace/image/icon/icon_facebook.png");}
// .topmenu img.facebook:hover {background-image: url("/workspace/image/icon/icon_facebook_green.png");}
// .topmenu img.linkedin {background-image: url("/workspace/image/icon/icon_linkedin.png");}
// .topmenu img.linkedin:hover {background-image: url("/workspace/image/icon/icon_linkedin_green.png");}
// .topmenu img.xing {background-image: url("/workspace/image/icon/icon_xing.png");}
// .topmenu img.xing:hover {background-image: url("/workspace/image/icon/icon_xing_green.png");}
// .topmenu img.twitter {background-image: url("/workspace/image/icon/icon_twitter.png");}
// .topmenu img.twitter:hover {background-image: url("/workspace/image/icon/icon_twitter_green.png");}
// .topmenu img.google {background-image: url("/workspace/image/icon/icon_google.png");}
// .topmenu img.google:hover {background-image: url("/workspace/image/icon/icon_google_green.png");}
// .topmenu img.home {background-image: url("/workspace/image/icon/icon_home.png");}
// .topmenu img.home:hover {background-image: url("/workspace/image/icon/icon_home_green.png");}
// .topmenu img.contact {background-image: url("/workspace/image/icon/icon_mail.png");}
// .topmenu img.contact:hover {background-image:	url("/workspace/image/icon/icon_mail_green.png");}
// .topmenu img.phone {background-image: url("/workspace/image/icon/icon_telefon.png");}
// .topmenu img.phone:hover {background-image:	url("/workspace/image/icon/icon_telefon_green.png");}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.topmenu * {
		margin-left:3px;
		margin-right:3px;
	}

	.topmenu input[name=search] {
		display:none;
	}

	.topmenu a[name=en] {
		content:"en";
	}

	.topmenu a[name=de] {
		content:"de";
	}
}

/*
*	Bottom Menu
*/

	.bottommenu  {
		text-align: center;
		background-color: c(silver);
		height: auto;
		line-height: 45px;
		min-width: 320px;
	}

	.bottommenu a {
		color:c(white);
		text-decoration: none;
		margin-left:10px;
		margin-right:10px;
		font-size:13px;
	}

	.bottommenu a:hover {
		color:c(lightblue);
	}

/**
* Menu
*/

	.menu {
		margin-top: 20px;
		z-index: 2;
		position: relative;
		border-right: 10px solid transparent;
		margin-right: 10px;
	}

	.menu.absolute {
		width: 61%;
		/*position:absolute;*/
	}

@media (min-width: 640px) and (max-width: 1000px) {/*medium*/
	.menu.absolute {
		width: 71%;
		/*position:absolute;*/
	}
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.menu, .menu.absolute  {
		width: auto;
		position:relative;
		border:0px;
	}
}

	.menu:after {
		content:"";
		clear:both;
		display:block;
	}

	.menu .haupt {
		overflow: hidden;
		float: left;
		padding-left: 0;
	}

	.menu p.mainCaption {
		font-weight: bold;
		font-size: 15px;
		text-decoration: none;
		line-height: 21px;
		padding: 0px;
		border-bottom: 1px solid c(black);
		margin-bottom: 10px;
		width: 75%;
		border-right: 10px solid white;
		box-sizing: border-box;
	}

	.menu .adds {
		overflow: hidden;
		width:25%;
		float: left;
	}

	.menu .haupt a img ,
	// .menu .haupt li img
	 {
		max-height:12px;
		padding-right:5px;
	}

	.menu .haupt a,
	// .menu .haupt li,
	.menu .adds a {
		display:block;
		float: left;
		text-align: left;
		border-top: 1px solid transparent;

		padding-right:6px;
		padding-left:6px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		cursor:pointer;

		color:c(black);
		text-decoration:none;
		font-size: 1rem;
		line-height: 30px;
		font-weight: bold;
	}

	.menu .haupt a:first-of-type{
		padding-left:0px;
	}

	.menu .haupt a.active{
		color: c(blue);
	}

	.menu .adds a {
		width: 100%;
		background-color:c(lightblue);
	}
	// .menu .haupt li:not(.active):hover,
	.menu .haupt a:hover,
	.adds a:hover {
		color: c(blue) !important;
	}

	@media (max-width: 640px){
		.menu .haupt li {
			margin-bottom: 0.5rem;
			width: 100%;
			padding-left: 1rem;
			padding-right: 1rem;
			margin-right: 0;
		}
	}
	.menu .haupt li.active {
		background-color: c(middleblue) !important;
		padding: $spacing;
	}
	.menu .haupt a:last-child {border-right:6px solid transparent;}

	.menu .adds a:last-child {border:0px;}



@media (min-width: 640px) and (max-width: 1000px) { /*medium*/
	.menu .adds {
		display:none;
	}

	/*.menu .haupt a:nth-child(5) {
		border:0px;
	}*/

	.menu .haupt a,
	// .menu .haupt li,
	.menu .adds a {
		padding-right:4px;
		padding-left:4px;
	}
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.menu {
		margin-top: 10px;
	}

	.menu .haupt {
		width:100%;
		margin: 0px;
		box-sizing:border-box;
		// -webkit-box-shadow: 4px 4px 4px c(black);
		// -moz-box-shadow:    4px 4px 4px c(black);
		// box-shadow: 4px 4px 4px c(black);
		margin-bottom: 10px;
		display:none;
	}



	// .menu .haupt li
		.menu .haupt a {
		width:100%;
		border-left: 1px solid c(lightsilver) !important;
		border-right: 1px solid c(lightsilver) !important;
		background-color:c(white);
		padding: 6px 10px !important;
	}
// .menu .haupt li:not(:first-of-type)
	.menu .haupt a:not(:first-of-type) {
		border-top: 1px solid c(lightsilver) !important;
	}

	// .menu .haupt li
	.menu .haupt a	 {
		border-bottom: 0px !important;
	}

}
.button.grey.menuB {
	// font-family: "Frutiger LT W01_65 Bold1475746";
	// background-image: url(/workspace/image/icon/arrow_down_white_small.png);
	text-align:center;
	margin-bottom: 10px;
	background-color: c(silver);
}

.button.grey.menuB::before {
	background-image: none;
}

.button.grey.menuB.active {
	// margin-bottom:0px;
	// background-image: url(/workspace/image/icon/arrow_up_white_small.png);
}

.button.grey.menuB:hover {
	// background-image: url(/workspace/image/icon/arrow_down_green_small.png) !important;
}

.button.grey.menuB.active:hover {
	// background-image: url(/workspace/image/icon/arrow_up_green_small.png) !important;
}

@media (min-width: 640px) {/*medium*/
	.button.grey.menuB {
		display:none;
	}
}
@media (min-width: 640px) {/*medium*/
		.menu .haupt {
			display:block !important;
		}
	}

	.logo {
		width:179px;
		float: left;
		margin: 20px 0px;
		position: absolute;
		height: 30px;
	}

	.logo img {
		display: block;
		width:100%;
	}

	.logo a {
	    width: 179px;
	    top: -36px;
	    left: -26px;
	    position: relative;
	}

@media (min-width: 640px) and (max-width: 1000px) { /*medium*/

	.logo a {
	    width: 170px;
	    top: -34px;
	    left: 0px;
	    position: relative;
	}
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.logo {
		position:static;
		height:auto;
		float: none;
		margin-left:auto;
		margin-right:auto;
	}

	.logo a {
		position:static;
		top:auto;
		left:auto;
		width:170px;
	}

}

.text .offerMenu a {
	background-image: none;
	width: 20%;
	display: inline-block;
	text-align: center;
	padding:0px;
	cursor:pointer;
}


.pageHeader {
	border-left: 10px solid c(white);
	border-right: 10px solid c(white);
	margin-left: 10px;
	margin-right: 10px;
	z-index: 2;
	position: relative;
	min-height: 60px;
}

.pageHeader > * {
	float:left;
}

.pageHeader > p {
	height:100%;
	line-height: 60px;
	text-align: center;
	font-size: 25px;
	margin-left: 20px;
}

@media (min-width: 1001px) {/*maximal*/
	.pageHeader.portal > p {
		line-height: 30px;
	}
}

@media (min-width: 639px) and (max-width: 1280px) {/*maximal*/
	.pageHeader > * {
		float: none;
	}

	.pageHeader > p {
		margin-left: 0px;
		text-align:left;
	}
}

.pageHeader img, .pageHeader a {
	max-height:60px;
}

#logo {
	margin-top: -6px;
}

.pageHeader > .button {
	width: 170px;
	text-align: center;
	position: absolute;
	top: 0px;
	right: 0px;
	box-sizing: border-box;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	padding: 5px 10px;
	margin:0px;
}

@media (min-width: 0px) and (max-width: 639px) {/*minimal*/
	.pageHeader {
		text-align:center;
		border:none;
		padding:0px;
	}

	.pageHeader > * {
		float: none;
	}

	.pageHeader > p {
		line-height: 23px;
		font-size: 18px;
		margin-left: 0px;
	}

	.pageHeader > .button {
		height: auto;
		float:none;
		width:100%;
		box-sizing:border-box;
		position: relative;
		margin-top:20px;
		border: 0px;
	}

	.pageHeader > .button br {
		display:none;
	}
}
