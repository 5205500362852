/*
---------------------------------------
Header
---------------------------------------
*/

.header {
    position: relative;
    background: $primary;
    color: c(white);
    // transition: background t(short);
    border-bottom: 1px solid $primary;

    @include bp(md) {
        position: relative;
        top: auto;
    }

    > .container {
        /*transition: all t(short);*/

        @include bp(md) {
            flex-wrap: nowrap;
            padding-top: $spacing;
            padding-bottom: $spacing;
        }
    }

    &.header--sticky {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: z(header);
    }

    &.header--scrolled {
        background: $primary;
        border-bottom: 1px solid c(grey);

        > .container {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.header__logo {
    display: block;
    margin: auto 0;
    padding: 0;
    margin-right: auto;
    font-size: .875rem;
    text-transform: uppercase;
    font-weight: bold;
    color: c(darkgrey);

    i {
        font-size: 1.2rem;
        color: $secondary;
        transition: all t(short);
    }

    span {
        text-transform: uppercase;
        color: $secondary;
        transition: all t(short);
    }

    &:hover {
        i,span {
            color: $primary;
        }
    }

    a {
        display: block;
        color: inherit;
        font-weight: bold;
    }

    img {
        display: block;
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: -2px;
        color: c(black);
        border: none;
    }
}

.header__c2a {
    margin: auto 0;
    order: 2;

    @include bp(md) {
        order: 1;
        margin-left: $spacing;
    }
}
