/*
---------------------------------------
Product Card
---------------------------------------
*/

.branchenkachel {
    position: relative;
    display: block;
    transition: all t(quick);
    text-decoration: none;
    color: c(black);
    margin: 0 0 $spacing;
    border: 1px solid c(grey);
    background: c(white);
}

.branchenkachel__imgwrapper {
    display: flex;
    overflow: hidden;
    text-align: center;
    //background: c(lightgrey);
    height: 5rem;

    @include bp(md) {
        height: 8rem;
    }

    @include bp(lg) {
        height: 9rem;
    }
}

.branchenkachel__img {
    position: relative;
    display: block;
    margin: 0 auto;
    flex: 1 1 auto;
    height: auto;
    object-fit: cover;
    max-width: 100%;
    transition: all t(short);
    z-index: z(product-card-img);
    transform: translate3d(0, 0, 0);
}

.branchenkachel__contentwrapper {
    display: flex;
    margin: 0;
    padding: $spacing;
    flex-wrap: wrap;

    @include bp(md) {
        flex-wrap: nowrap;
    }
}

.branchenkachel__content {
    flex: 1 1 auto;
    width: 50%;
}

.branchenkachel__title {
    display: block;
    overflow: hidden;
    font-size: 1rem;
    margin: 0 0 $spacing * .5 0;
}
