/*
---------------------------------------
Header
---------------------------------------
*/

.box_header {
    position: relative;
    color: black;
    text-align: center;
    }

  