/*
---------------------------------------
Typography
---------------------------------------
*/

.big,
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    display: block;
    margin: 0 0 $spacing;
    font-family: f(sans);
    font-weight: 500;
    line-height: 1.2;

    > a {
        color: inherit;
    }
}

p, .p {
    display: block;
    margin: 0 0 $spacing;
    font-family: f(sans);

    + h1,+ .h1,
    + h2,+ .h2,
    + h3,+ .h3,
    + h4,+ .h4,
    + h5,+ .h5,
    + h6,+ .h6 {
        margin-top: 1.5em;
    }
}

.big    { @include big; }
h1, .h1 { @include h1; }
h2, .h2 { @include h2; }
h3, .h3 { @include h3; }
h4, .h4 { @include h4; }
h5, .h5 { @include h5; }
h6, .h6 { @include h6; }
p, .p   { @include p; }
ul, .ul, ol, .ol   { @include list; }

.lead {
    @include lead;
    opacity: .7;
}

small, .small {
    font-size: 80%;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    outline: 0;
    margin: $spacing 0;
    padding: 0;
    background: c(grey);
}

code {
    display: inline-block;
    font-family: f(mono);
    font-size: .75rem;
    letter-spacing: 0;
    background: rgba(c(black), .1);
    padding: .2em .8em .15em;
    border-radius: 3px;
    white-space: nowrap;

    &:hover {
        background: rgba(c(black), .2);
    }
}

blockquote {

}

.more {
    display: inline-block;
    color: $primary;
    transition: all t(short);

    &::after {
        @include icon('\f105');
        display: inline-block;
        margin-left: .7em;
        transform: translateX(-.2em);
        transition: all t(short);
    }

    &:hover {
        color: $primary;

        &::after { transform: translate(0); }
    }
}
