#linkCards {
    margin-top: $spacing * 2.5;
}
.linkCardCol {
    display: flex;
    flex: 1 0 auto;
    width: auto;

    .linkCard {
        background-color: $primary;
        border-radius: 0;
        display: flex;
        flex-wrap: wrap;
        color: c(white);
        height: auto;
        flex: 1 1 auto;

        .card__header {
            flex: 1 1 100%;
            text-align: center;
            padding: 1;
            padding-top: 3rem;
        }
        .card__body {
            flex: 1 1 100%;
            text-align: center;
            padding: 0;
        }

        .button--wrapper {
            flex: 1 1 100%;
            padding: 1rem;
            padding-top: 0;
            vertical-align: bottom;
            margin-top: auto;
            margin-bottom: 0;
            text-align: center;
            .a {
                text-align: center;
            }
            .btn {
                width: 48px;
                height: 48px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0;

                i {
                    font-size: 2rem;
                }

                @include bp (lg) {
                    // width: calc(50% - 0.5rem);
                    // margin-left: 0;
                    // margin-right: $spacing * 0.5;
                }
                &:last-child {

                    @include bp (lg) {
                        // margin-left: $spacing * 0.5;
                        // margin-right: 0;
                    }
                }

            }
        }
    }
}
