/**
 * Windows Phone 8 and Device-Width FIX - CSS PART
 * Source: http://timkadlec.com/2013/01/windows-phone-8-and-device-width/
 */
@-webkit-viewport   { width: device-width; }
@-moz-viewport      { width: device-width; }
@-ms-viewport       { width: device-width; }
@-o-viewport        { width: device-width; }
@viewport           { width: device-width; }


html {
    box-sizing: border-box;
    line-height: normal;
    width: 100%;
    height: 100%;
    font-size: 100%;
    font-family: f(sans);
    // text-rendering: optimizeLegibility;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    // -ms-text-size-adjust: 100%;
    // -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    color: c(black);
    min-width: 320px;
}

a {
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: none;
}

img {
    max-width: 100%;
    height: auto;
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base
[tabindex="-1"]:focus {
    outline: none !important;
}


#no-js-hint {
    display: none;
}

.no-js #no-js-hint {
    display: block;
}
