/*
---------------------------------------
Card Layout
---------------------------------------
*/

.card {

    border-radius: $border-radius;
    margin: $spacing auto;
    overflow: hidden;
}

.card__header,
.card__footer {
    padding: $spacing;
}

.card__header > * {
    margin-bottom: 0;
    line-height: 1;

}

.card__body {
    padding: $spacing;
}

.card__image {
    width: 100%;
    height: auto;
    margin: 0;
}

.card__links {
    margin: $spacing 0 0;
    padding: $spacing 0 0 0;
    line-height: 1;
    border-top: 1px solid c(grey);

    a {

    }
}
