// variables
// --------------------------

$fa-css-prefix          : fa !default;
$fa-style               : 900 !default;
$fa-style-family        : "Font Awesome 6 Pro" !default;

$fa-display             : inline-block !default;

$fa-fw-width            : fa-divide(20em, 16) !default;
$fa-inverse             : #fff !default;

$fa-border-color        : #eee !default;
$fa-border-padding      : .2em .25em .15em !default;
$fa-border-radius       : .1em !default;
$fa-border-style        : solid !default;
$fa-border-width        : .08em !default;

$fa-size-scale-2xs      : 10 !default;
$fa-size-scale-xs       : 12 !default;
$fa-size-scale-sm       : 14 !default;
$fa-size-scale-base     : 16 !default;
$fa-size-scale-lg       : 20 !default;
$fa-size-scale-xl       : 24 !default;
$fa-size-scale-2xl      : 32 !default;

$fa-sizes: (
  "2xs"                 : $fa-size-scale-2xs,
  "xs"                  : $fa-size-scale-xs,
  "sm"                  : $fa-size-scale-sm,
  "lg"                  : $fa-size-scale-lg,
  "xl"                  : $fa-size-scale-xl,
  "2xl"                 : $fa-size-scale-2xl
) !default;

$fa-li-width            : 2em !default;
$fa-li-margin           : $fa-li-width * fa-divide(5, 4) !default;

$fa-pull-margin         : .3em !default;

$fa-primary-opacity     : 1 !default;
$fa-secondary-opacity   : .4 !default;

$fa-stack-vertical-align: middle !default;
$fa-stack-width         : ($fa-fw-width * 2) !default;
$fa-stack-z-index       : auto !default;

$fa-font-display        : block !default;
$fa-font-path           : "../webfonts" !default;

$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-angle-up: \f106;
$fa-var-angles-left: \f100;
$fa-var-angle-double-left: \f100;
$fa-var-angles-right: \f101;
$fa-var-angle-double-right: \f101;
$fa-var-at: \40;
$fa-var-bars: \f0c9;
$fa-var-navicon: \f0c9;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-file-pdf: \f1c1;
$fa-var-flag: \f024;
$fa-var-paper-plane: \f1d8;
$fa-var-phone: \f095;
$fa-var-road: \f018;
$fa-var-xmark: \f00d;
$fa-var-close: \f00d;
$fa-var-multiply: \f00d;
$fa-var-remove: \f00d;
$fa-var-times: \f00d;

$fa-var-facebook-f: \f39e;
$fa-var-linkedin-in: \f0e1;
$fa-var-x-twitter: \e61b;
$fa-var-xing: \f168;

$fa-icons: (
  "angle-down": $fa-var-angle-down,
  "angle-left": $fa-var-angle-left,
  "angle-right": $fa-var-angle-right,
  "angle-up": $fa-var-angle-up,
  "angles-left": $fa-var-angles-left,
  "angle-double-left": $fa-var-angle-double-left,
  "angles-right": $fa-var-angles-right,
  "angle-double-right": $fa-var-angle-double-right,
  "at": $fa-var-at,
  "bars": $fa-var-bars,
  "navicon": $fa-var-navicon,
  "chevron-left": $fa-var-chevron-left,
  "chevron-right": $fa-var-chevron-right,
  "file-pdf": $fa-var-file-pdf,
  "flag": $fa-var-flag,
  "paper-plane": $fa-var-paper-plane,
  "phone": $fa-var-phone,
  "road": $fa-var-road,
  "xmark": $fa-var-xmark,
  "close": $fa-var-close,
  "multiply": $fa-var-multiply,
  "remove": $fa-var-remove,
  "times": $fa-var-times,
);

$fa-brand-icons: (
  "facebook-f": $fa-var-facebook-f,
  "linkedin-in": $fa-var-linkedin-in,
  "x-twitter": $fa-var-x-twitter,
  "xing": $fa-var-xing,
);

$fa-custom-icons: (
);
