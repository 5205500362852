.product-pagination {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.pagination {
 list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: 2px;}


  
.page-item {
  background-color: #039;
  text-decoration:none;
  font-weight: 700;
  min-width:44px;
  min-height:44px;
  display: flex;
  justify-content: center;  
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,.35);
}




.page-item:hover {
  background-color: rgb(149, 166, 166);
  text-decoration:none;
  font-weight: 700;
  min-width:44px;
  min-height:44px;
  display: flex;
  color: black;
  justify-content: center;  
  
}

.page-item:hover {
  background-color: rgb(149, 166, 166);
  text-decoration:none;
  font-weight: 700;
  min-width:44px;
  min-height:44px;
  display: flex;
  color: black;
  justify-content: center;  
  
}


.page-item.active {
   background-color: rgb(149, 166, 166);
}

li a  {
  font-weight: 500;
  text-decoration:none;  
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 14px;
  padding-left: 10px;
}

.page-link {
    color: white;
    width: 50px;
    height: 50px;
    text-align: center;
    padding-left: 0px;
}