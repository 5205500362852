.branchentextcontainer--wrapper {
    padding: $spacing * 2;
    background-color: c(silver);
}
.branchentextcontainer {

    ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            flex: 1 1 100%;
            list-style: none;
            padding-right: $spacing;
            font-weight: bold;

            @include bp(md) {
                flex: 1 1 50%;
            }
        }
    }
}

.branchenwrapper {
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .branchenimg {
        height: auto;
        min-height: calc(20rem - 44px - #{$spacing} * 0.5);
        flex: 0 0 100%;
        background-position: center;
        background-size: cover;

        @include bp(md) {
            min-height: calc(100% - 88px - #{$spacing});
        }

        @include bp(lg) {
            min-height: calc(100% - 44px - #{$spacing} * 0.5);
        }

    }
    .branchenbutton--wrapper {
        margin-top: 0.5rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .btn {
            margin-bottom: 0;

            width: 100%;
            padding-left: $spacing;
            padding-right: $spacing;
            margin-right: 0;

            @include bp(sm) {
                width: calc(50% - #{$spacing} * .25);
                margin-right: $spacing * 0.25;
            }

            @include bp(md){
                margin-right: 0;
                width: 100%;
            }

            @include bp(lg) {
                width: calc(50% - #{$spacing} * .25);
                margin-right: $spacing * 0.25;
            }
        }
        .btn:last-child {
            margin-top: $spacing * 0.5;
            @include bp(sm) {
                margin-top: 0;
                margin-left: $spacing * 0.25;
                margin-right: 0;
            }

            @include bp(md) {
                margin-top: $spacing * 0.5;
                margin-left: 0;
                margin-right: 0;
            }


            @include bp(lg) {
                margin-top: 0;
                margin-left: $spacing * 0.25;
                margin-right: 0;
            }

        }
    }
}
