/*
---------------------------------------
Basic Buttons
---------------------------------------
*/

@mixin btn {
    display: inline-block;
    margin: 0 $spacing / 2 $spacing / 2 0;
    padding: $spacing $spacing * 2.5;
    -webkit-appearance: none;
    vertical-align: middle;

    font-family: f(sans);
    font-size: .75rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: .5pt;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;

    border: 0;
    border-radius: 5px;
    background: c(black);
    color: c(white);

    &.btn--hover,
    &.btn--focus,
    &:focus,
    &:hover{
        cursor: pointer;
        outline: none;
        background: lighten(c(black), 10%);
        color: c(white);
    }

    &.btn--active,
    &:active {
        box-shadow: none;
        transform: translateY(3px);
    }

    &[disabled] {
        opacity: .8;
        background: c(grey) !important;
        cursor: not-allowed;
    }
}

.btn {
    @include btn;
}

/*
 * Color
 */
.btn--primary {
    background: $primary;
    color: c(white);

    &:focus,
    &:hover,
    &:active {
        color: c(white);
        border-color: lighten($primary, 10%);
        background: lighten($primary, 10%);
    }
}

.btn--secondary {
    background: $secondary;
    color: c(black);

    &:focus,
    &:hover,
    &:active {
        color: c(black);
        border-color: lighten($secondary, 10%);
        background: lighten($secondary, 10%);
    }
}
.btn--tertiary {
    background: $tertiary;
    color: c(white);

    &:focus,
    &:hover,
    &:active {
        color: c(white);
        border-color: lighten($tertiary, 10%);
        background: lighten($tertiary, 10%);
    }
}
/*
 * Size
 */
.btn--small {
    padding: $spacing * .8 $spacing * 1.2;
}

.btn--big {
    @include bp(md) {
        font-size: 1rem;
        padding: $spacing * 1.5 $spacing * 4;
    }
}

/*
 * Variants
 */
.btn--outline {
    background: none;
    border: 1px solid white;

    &:focus,
    &:hover,
    &:active {
        color: $primary;
        border-color: c(white);
        background: c(white);
    }
}

.btn--round {
    border-radius: 100px;
}


.tile {
    background-image: linear-gradient(rgba(255,204,0,.9),rgba(255,204,0,.7));
    width: 20%;
    padding: 3rem 1rem;
    margin: 1rem;
    font-size: 1.5rem;
    hyphens: auto;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background-image: linear-gradient(rgba(255,204,0,1),rgba(255,204,0,.8));
        box-shadow: 0 0 50px 0px rgba(c(black), .2);
    }

    &:nth-child(n+8) {
        display: none;
    }
}
