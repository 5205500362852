.socialMedia {



    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    padding: 0.5rem 0;

    @include bp(lg) {
        height: auto;
        margin-right: 0;
        order: 2;
    }

    a {
        margin: auto 0;
        i {
            font-size: 2rem;
            width: 3rem;
            text-align: center;
            color: c(white);
        }
        i:hover {
            color: $secondary;
        }

    }
}
