/*
---------------------------------------
Variables
---------------------------------------

Defining variables for the whole project.
Grid-settings, spacings, colors, font-families and more.

*/

/**
 * Font Families
 * Use with mixin: font-family(sans) or f(sans);
 */
$fonts: (
    sans: #{Roboto, Helvetica, Arial, sans-serif},
    serif: #{Georgia, 'Times New Roman', sans-serif},
    mono: #{'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace},
    icon: #{'Font Awesome 6 Pro'},
);

/**
 * Color Palett
 * Use with mixin: color(black) or c(black);
 */
$colors : (
    transparent: rgba(black, 0),

    black: #111,
    white: #fff,

    lightgrey: #FAFAFA,
    grey: #ECECEC,
    darkgrey: #424242,

    red: #e91e63,
    blue: #003399,
    middleblue: rgba(0,51,153,0.6),
    lightblue: rgba(0,51,153,0.2),
    green: #8bc34a,
    yellow: #FFCC00,
    orange: #ff9800,
    silver: #95a6a6,
    middlesilver: #cad2d2,
    lightsilver: #e4e8e8,
);

$primary: c(blue);
$secondary: c(yellow);
$tertiary: c(middleblue);

/**
 * z-index
 * Use with mixin: z-index(default) or z(default);
 */
$z-layers: (
    bottomless-pit: -9999,
    default: 1,
    product-card-img: 2,
    product-card-imgOverlay:3,
    product-card-label: 4,
    header: 10,
    dropdown: 20,
    back-to-top: 30,
    blackout: 99,
    overlay: 100,
    sidepanel: 101
);

/**
 * timings
 * Use with mixin: timing(short) or t(short)
 */
$timings: (
    quick: .2s ease,
    short: .5s ease,
    long: 2s ease
);

$border-radius: 5px;

$spacing: 1rem;

/**
 * Grid
 */
$grid-breakpoints: (
  'xs': 320px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px
);

$grid-max-width: 1200px;
$grid-values: (5, 10, 15, 16, 20, 25, 30, 33, 35, 40, 45, 50, 55, 60, 65, 66, 70, 75, 80, 85, 90, 95, 100);
