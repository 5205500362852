/*
---------------------------------------
Section
---------------------------------------
*/

.section {
    @include section-spacing(1.25);
    position: relative;

    &.section--border {
        border-top: 1px solid c(grey);
        border-bottom: 1px solid c(grey);
        background: c(lightgrey);
    }

    &.section--angled {
        clip-path: polygon(0 0%, 100% 4%, 100% 96%, 0% 100%);
        background-image: linear-gradient( 135deg, $primary 0%, darken($primary, 10%) 100%);
    }

    &.section--animate {
        > * {
            opacity: 0;
            transform: translateY($spacing);
            transition: all 1s ease;
        }
    }

    &.section--animated {
        > * {
            transform: translateX(0);
            opacity: 1;
        }
    }

    .section__headline {
        @include h2;
        // font-weight: bold;
        font-family: f(sans);
        color: c(black);
        text-transform: uppercase;
        text-align: center;
        -ms-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
        margin: 0 0 $spacing * 3;

        @include bp(md) {
            margin: 0 0 $spacing * 4;
        }

        ~ .section__subline,
        ~ .section__text {
            margin-top: $spacing * -2.5;
            @include bp(md) {
                margin-top: $spacing * -3.5;
            }
        }
    }
    .section__subline {
        @include lead;
        display: block;
        text-align: center;
        text-transform: uppercase;
        opacity: .8;
        margin: 0 auto;
        margin-bottom: $spacing * 3;

        @include bp(md) {
            margin-bottom: $spacing * 5;
        }

        ~ .section__text {
            margin-top: $spacing * -2.5;
            @include bp(md) {
                margin-top: $spacing * -4.5;
            }
        }
    }

    .section__text {
        @include p;
        text-align: center;
        max-width: $grid-max-width / 2;
        margin: 0 auto;
        margin-bottom: $spacing * 3;

        @include bp(md) {
            margin-bottom: $spacing * 5;
        }
    }
    &.section--alternate {
        background: linear-gradient(135deg, c(middleblue), c(lightblue));
        margin-bottom: 3rem;
        margin-top: 4rem;

        .section__headline {
            margin-top: 4rem;
        }
    }
    &.section--tertiary {
        background-color: c(grey);
    }
}
