#Stellenausschreibung {
    position: relative;
}
.nav__stellenanzeige--wrapper {
    height: 100%;
    display: block;
    padding-bottom: 4rem;

    @include bp(lg) {
        height: 4rem;
        padding-bottom: 9rem;
    }

    .nav__stellenanzeige {
        background-color: rgba(0,0,0,0);
        margin-left: auto;
        margin-right: auto;
        left: auto;
        right: auto;
        text-align: center;

        .container {
            background-color: $tertiary;
            // height: 4rem;
            vertical-align: middle;
            padding-top: .15rem;
            padding-bottom: .15rem;
            @include bp(lg) {
                padding-top: 0;
                padding-bottom: 0;
                height: 4rem;
            }

            .row {
                text-align: right;
                @include bp(lg) {
                    height: 4rem;
                }
                .socialMedia {
                    order: 0;
                }


                .btn {
                    margin-top: .2rem;
                    margin-bottom: .2rem;
                    margin-right: .2rem;
                    margin-left: .2rem;
                    vertical-align: middle;
                    flex: 1 1 100%;

                    @include bp(lg){
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-right: 1rem;
                        margin-left: 1rem;
                        vertical-align: middle;
                        flex: 0 0 auto;
                    }
                    i {
                        font-size: 1rem;
                    }


                }

                i.fa-facebook-f:hover {
                    color: #3b5998;
                }
                i.fa-twitter:hover {
                    color: #1dcaff;
                }
                i.fa-xing:hover {
                    color: #026466;
                }
                i.fa-linkedin-in:hover {
                    color: #0077b5;
                }
                .btn--primary {
                    align-content: flex-end;
                    @include bp(lg) {
                        margin-right: 1rem;
                        margin-left: auto;
                    }
                }
            }
        }
    }
}

.fixed {
    @include bp(lg) {
        position: fixed;
        height: 4rem;
        z-index: 15;
        width: 100%;
        top: 49px;
    }
}
.absolute {
    @include bp(lg) {
        position: absolute;
        bottom: 4rem;
        height: 4rem;
        width: 100%;
        z-index: 9;
    }
}
// #Stellenausschreibung.Stellenausschreibung--active {
//     padding-top: calc(49px + 9rem);
// }

