/**
 * Slick Slider
 *
 * Use the slick slider in modules to overwrite the styles listed in here.
 */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: c(transparent);
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus { outline: none; }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after { clear: both; }

    .slick-loading & { visibility: hidden; }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & { float: right; }
    img { display: block; }

    &.slick-loading img { display: none; }

    display: none;

    &:focus { outline: none; }
    &.dragging img { pointer-events: none; }
    .slick-initialized & { display: flex; }
    .slick-loading & { visibility: hidden; }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    line-height: 1;
    font-size: 0;
    cursor: pointer;
    background: c(transparent);
    color: rgba(c(black), .3);
    top: 50%;
    transform: translate(0, -50%);
    padding: $spacing;
    border: none;
    outline: none;
    z-index: z(default);
    border-radius: 0;
    transition: all t(quick);

    &:hover,
    &:focus {
        outline: none;
        background: c(transparent);
        color: rgba(c(black), .5);

        &:before { opacity: 1; }
    }

    &:hover {
        background: rgba(c(black), .1);
    }

    &.slick-disabled:before {
        opacity: .8;
    }

    &:before {
        font-size: 2rem;
        line-height: 2rem;
        color: inherit;
        opacity: 1;
    }
}

.slick-prev {
    left: 0;

    [dir="rtl"] & {
        left: auto;
        right: 0;
    }

    &:before {
        @include icon('\f104');
        font-family: "Font Awesome 6 Pro";

        [dir="rtl"] & {
            @include icon('\f105');
            font-family: "Font Awesome 6 Pro";
        }
    }
}

.slick-next {
    right: 0;

    [dir="rtl"] & {
        left: 0;
        right: auto;
    }

    &:before {
        @include icon('\f105');
        font-family: "Font Awesome 6 Pro";

        [dir="rtl"] & {
            @include icon('\f104');
            font-family: "Font Awesome 6 Pro";
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: 10px;
        width: 10px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: 10px;
            width: 10px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;

            &:hover,
            &:focus {
                outline: none;

                &:before { opacity: 1; }
            }

            &:before {
                @include icon('\f111');
                font-family: "Font Awesome 6 Pro";
                position: absolute;
                top: 0;
                left: 0;
                width: 10px;
                height: 10px;
                font-size: 11px;
                line-height: 1;
                text-align: center;
                color: rgba(c(black), .2);
                opacity: .8;
            }
        }
        &.slick-active button:before {
            color: $primary;
            opacity: 1;
        }
    }
}
