.section.textboxen,
.section.textboxen--alternate {
    padding: 0;

    .row {
        margin-bottom: $spacing;

        [class*=col-] {
            margin-bottom: $spacing;
        }

        @include bp(md) {
            margin-bottom: 0;

            [class*=col-] {
                margin-bottom: 0;
            }
        }

    }
}
.highlighttextbox {
    display: flex;


    .highlighttextbox__wrapper {
        display: flex;
        color: c(white);
        padding: $spacing * 3;
        background-color: $primary;
        width: 100%;

        .highlighttextbox__container {
            flex-direction: column;
            display: flex;
            width: 100%;

            .highlighttextbox__innerbox {
                margin-top: auto;
                margin-bottom: auto;
                height: 100%;
                display: flex;
                border-bottom: c(white) solid 1px;

                &:last-child {
                    border: 0;
                }

                .slide__wrapper {
                    display: flex;
                    align-items: center;
                    text-align: left;
                    vertical-align: top;
                    margin: auto;
                    color: c(white);
                    margin-left: 15px;

                    .slide__icon {
                        width: auto;
                        height: auto;

                        svg, img {
                            height: auto;
                            width: 64px;
                        }
                    }
                    .slide__headline {
                        margin-left: 1rem;
                        margin-top: 0;
                        margin-bottom: auto;

                        h4 {
                            font-size: 1.625rem;
                            line-height: 1;
                            font-weight: 500;
                            color: c(white);
                        }
                        p {
                            font-size: 1rem;
                            line-height: 1;
                            color: c(white);
                        }

                    }
                }
            }

        }
      .highlighttextbox__container {
        gap: 25px;
      }
    }
}

.highlighttextbox--alternate {

    .highlighttextbox__wrapper {
        background-color: $secondary;
    }
}

.textboxen {
    // background-color: c(lightsilver);

    .container {

        .section__headline {
            padding-top: $spacing * 2.5;
        }
        .row {
            flex-direction: column-reverse;
            margin-bottom: $spacing;

            [class*=col-] {
                margin-bottom: $spacing;
            }

            @include bp(md) {
                flex-direction: row;
                margin-bottom: 0;

                [class*=col-] {
                    margin-bottom: 0;
                }

            }
        }
    }
}
.arrow__wrapper {
    display: none;
    @include bp (md) {
    width: 25%;
    display: block;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    }

    img {
        width: 100%;
        margin: auto;
    }
}
