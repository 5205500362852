/*
---------------------------------------
Grid
---------------------------------------
*/

$grid-breakpoints: (
  'xs': 320px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px
) !default;

$grid-max-width: 1200px !default;

// Available grid size-classes
$grid-values: (5, 10, 15, 16, 20, 25, 30, 33, 35, 40, 45, 50, 55, 60, 65, 66, 70, 75, 80, 85, 90, 95, 100) !default;


/**
 * JS Breakpoint helpers
 * .js counterpart in `js/inc/_breakpoints.js`
 *
 * @source https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
 *
 */
body:before {
    display: none;
    content:'xs';

    @each $name, $size in $grid-breakpoints {
        @include bp($name) {
            content: '#{$name}';
        }
    }
}


.col,
[class*=col-]{
    @include flex-col;
}

.row {
    @include flex-row;
    @include flex-options;

    &.row--nogutter {
        margin-left: 0;
        margin-right: 0;
    }
}

.container {
    @include container;

    &.container--flex {
        @include flex-container;
    }

    &.container--full {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    &.container--narrow {
        max-width: $grid-max-width / 2;
    }
}


// Generate col-{breakpoint}-{size} classes
@each $name, $size in $grid-breakpoints {
    @include bp($name) {
        @each $va in $grid-values {
            .col-#{$name}-#{$va} {
                @include generate-col($va);
            }
            .col--offset-#{$name}-#{$va} {
                margin-left: 0% + $va;
            }
        }
    }
}
