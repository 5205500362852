/*
---------------------------------------
Footer
---------------------------------------
*/

.footer {
    display: block;
    font-size: .875rem;
    line-height: 1.7;
    padding: $spacing * 2 0;
    background: c(black);
    color: c(white);
    border-top: 1px solid c(darkgrey);
    margin-top: 36px;

    a {
        color: inherit;

        &:hover,
        &:active,
        &:focus {
            color: c(white);
        }
    }
}

.footer__copyright {
    @include col;
    text-align: center;

    font-size: .875rem;

    @include bp(sm) {
        @include generate-col(50);
        text-align:left;
        order: 1;
    }
}

.footer__links {
    @include col;
    margin: 0;
    list-style: none;
    text-align: center;

    font-size: .875rem;

    @include bp(sm) {
        @include generate-col(50);
        text-align:left;

    }

    > li {
        display: inline-block;
        padding: 0 1em;
    }
}


footer {
    .contact {
        border-bottom: solid c(white) 1px;
        padding-bottom: $spacing * 2;
        @include bp(md) {
            border-bottom: solid c(black) 0;
            padding-bottom: $spacing * 0;
        }

        ul {
            padding: 0;
            list-style: none;
            margin: 0;
        }
        i {
            width: 1.5rem;
        }

    }
    .utilities {
        padding: $spacing * 2 0;
        margin-bottom: $spacing;
        border-bottom: solid c(white) 1px;


        @include bp(md) {
            border-bottom: solid c(black) 0;
            padding: 0;
        }

        ul {
            list-style: none;
            padding-left: 0;
            text-align: center;
            margin: 0;

            li {
                display: inline-block;
                margin-left: $spacing * 0.5;
                margin-right: $spacing * 0.5;
            }
        }
    }
    .din {
        text-align: center;
        @include bp(md) {
            text-align: right;
        }

        img {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            width: 60%;

            @include bp(md){
                text-align: right;
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
}
.follow {
    display: flex;
    text-align: center;
    margin: auto;
    background-color: #ffcc00;
    color: #003399;
    font-weight: 500;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    transform: translateY(-72px);
    gap: 10px;
}

.follow--wrapper {
    display: flex;
    margin: auto;
    text-align: center;
    width: 320px;
}

.follow .sharing {
    margin: 0 0.5rem;
}
