/*
---------------------------------------
Slider
---------------------------------------
*/

.section.section--slider {
    @include section-spacing(1);
    background: linear-gradient(to bottom, c(white), c(lightgrey));
}

.slick > div {
    visibility: hidden;

    &:first-child {
        visibility: visible;
    }
}

.slick.slick-initialized > div {
    visibility: visible;
}

.slider {

    .slick-slider {
        padding: $spacing * 2 0;
    }
    .slick-track {}

    .slick-prev,
    .slick-next {}

    .slick-slide {
        text-align: center;

        &:focus {
            outline: none;
        }

        &.slick-current {}
    }
}
