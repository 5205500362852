

.texthighlight .container {
    text-align: center;
    display: flex;

    .texthighlight--wrapper {
        margin: auto;
        text-align: left;
        width: auto;

        b {
            color: $primary;
            font-size: 3rem;
        }
        small {
            font-weight: 300;
            font-size: 1rem;
        }
    }
}
